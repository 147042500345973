import React, { Component } from "react";
// api
// import { uiPosition } from "../../../api/Api";

// images
import img1 from "../../../assets/img/folder.png";
import img2 from "../../../assets/img/flyer.png";

import img6 from "../../../assets/img/Folder-Wickelfalz.png";
import img7 from "../../../assets/img/folder8.jpg";
import img8 from "../../../assets/img/folderz8.jpg";
import img9 from "../../../assets/img/folderz6.jpg";
import img10 from "../../../assets/img/rullup.jpg";
import img11 from "../../../assets/img/Lesezeichen.jpg";
import img12 from "../../../assets/img/ButtonB.jpg";
import img13 from "../../../assets/img/Visitenkarte_Standard.jpg";
import img14 from "../../../assets/img/icons/Visitenkarten-Klappkarten.jpg";
import img15 from "../../../assets/img/Stempel.jpg";

import img16 from "../../../assets/img/icons/Fotokalender.jpg";
import img17 from "../../../assets/img/icons/Broschuernkalender.jpg";
import img18 from "../../../assets/img/icons/Stehkalender.jpg";
import img19 from "../../../assets/img/icons/Taschenkalender.jpg";

import img20 from "../../../assets/img/icons/Sticekr.jpg";
import img21 from "../../../assets/img/icons/Klebebuchstaben.jpg";

import img22 from "../../../assets/img/icons/Klebefolien.jpg";
import img23 from "../../../assets/img/icons/Laminierte-Plakate.jpg";

import img25 from "../../../assets/img/icons/Plakate.jpg";

import img26 from "../../../assets/img/icons/Presspappe.jpg";
import img27 from "../../../assets/img/icons/Schaumplatten.jpg";
import img28 from "../../../assets/img/icons/Tischkarten.jpg";
import img29 from "../../../assets/img/icons/T-Shirts.jpg";
import img30 from "../../../assets/img/icons/Stofftaschen.jpg";

import img31 from "../../../assets/img/icons/Durchschreibblocke.jpg";
import img32 from "../../../assets/img/icons/Spiralblocke.jpg";
import img33 from "../../../assets/img/icons/Banner-Transparente.jpg";
import img34 from "../../../assets/img/icons/Brief.jpg";
import img35 from "../../../assets/img/icons/Broschuren.jpg";
import img36 from "../../../assets/img/icons/Buchdruck.jpg";

import img37 from "../../../assets/img/icons/Diplomarbeit.jpg";

import img38 from "../../../assets/img/icons/Eintrittskarte.jpg";
import img39 from "../../../assets/img/icons/Fotoposter.jpg";
import img40 from "../../../assets/img/icons/Fototapeten.jpg";
import img41 from "../../../assets/img/icons/Keilrahmen.jpg";
// import img42 from "../../../assets/img/icons/Lesezeichen.jpg";
import img43 from "../../../assets/img/icons/Loseblattsammelung.jpg";
import img44 from "../../../assets/img/icons/Personalisierte-Brief.jpg";
import img45 from "../../../assets/img/icons/Plandruck.jpg";
import img46 from "../../../assets/img/icons/Ruckenbandbindung.jpg";
import img47 from "../../../assets/img/icons/Softcoverbuchern.jpg";
import img48 from "../../../assets/img/icons/Spiralbuchern.jpg";
// import img49 from "../../../assets/img/icons/Werbetechnik.jpg";
import { NavLink } from "react-router-dom";

export default class gride extends Component {
  render() {
    return (
      <main>
        <div className="main1-home w-100 flex-j-c">
          <div className="main1-home-dad w-90 flex-j-s">
            <div className="main1-home-txt1">
              <div>Die Online-Digitaldruckerei im Herzen von Wien!</div>
            </div>
          </div>
        </div>
        <div className="main2-home w-100 flex-j-c">
          <div className="main2-home-dad w-90 flex-j-s wrap">
            <div className="main2-home-box1">
              <NavLink to="/buyFlyer">
                <img src={img2} alt="" />
                <div className="main2-home-txt1">FLYER</div>
                <div className="main2-home-txt2">
                  <a href="/buyFlyer">Flyer</a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <NavLink to="buyFolder">
                <img src={img1} alt="" />
                <div className="main2-home-txt1">Folder</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/folder-einfachfalz/">
                    Folder Einfachfalz
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <NavLink to="/buyFolderWickelfalz6seiten">
                <img src={img6} alt="" />
                <div className="main2-home-txt1">Folder</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/folder-einfachfalz/">
                    Folder Wickel falz 6seiten
                  </a>
                </div>
              </NavLink>
            </div>

            <div className="main2-home-box1">
              <NavLink to="/buyFolderWickelfalz8seiten">
                <img src={img7} alt="" />
                <div className="main2-home-txt1">Folder</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/folder-einfachfalz/">
                    Folder Wickel falz 8seiten
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <NavLink to="/buyFolderZfalz6Seiten">
                <img src={img9} alt="" />
                <div className="main2-home-txt1">Folder</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/folder-einfachfalz/">
                    Folder Zfalz 6Seiten
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <NavLink to="/buyFolderZfalz8Seiten">
                <img src={img8} alt="" />
                <div className="main2-home-txt1">Folder</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/folder-einfachfalz/">
                    Folder Zfalz 8Seiten
                  </a>
                </div>
              </NavLink>
            </div>

            <div className="main2-home-box1">
              <NavLink to="/buyrollup">
                <img src={img10} alt="" />
                <div className="main2-home-txt1">Roll-Up</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/klapp-visitenkarten/">
                    {/* Klapp Visitenkarten */}Roll-Up
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <NavLink to="/buyLesezeichen">
                <img src={img11} alt="" />
                <div className="main2-home-txt1">Lesezeichen</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/plakate-poster/">
                    Lesezeichen
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <NavLink to="/buyButton">
                <img src={img12} alt="" />
                <div className="main2-home-txt1">Button</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/plakate-poster/">
                    Button
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <NavLink to="/buyVisitenkarte_Standard">
                <img src={img13} alt="" />
                <div className="main2-home-txt1">Visitenkarte</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/plakate-poster/">
                    Visitenkarte_Standard
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <NavLink to="/buyVisitenkarte_Klapp">
                <img src={img14} alt="" />
                <div className="main2-home-txt1">Visitenkarte</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/plakate-poster/">
                    Visitenkarte_Klapp
                  </a>
                </div>
              </NavLink>
            </div>
            {/* <div className="main2-home-box1">
          <NavLink to="/buyProfessionalstempel">
            <img src={img15} alt="" />
            <div className="main2-home-txt1">Stempel</div>
            <div className="main2-home-txt2">
              <a href="https://teleprint.at/produkte/plakate-poster/">
                Professionalstempel
              </a>
            </div>
          </NavLink>
        </div> */}

            {/* <div className="main2-home-box1">
          <NavLink to="/buyProfessionalDatumstempel">
            <img src={img15} alt="" />
            <div className="main2-home-txt1">Stempel</div>
            <div className="main2-home-txt2">
              <a href="https://teleprint.at/produkte/plakate-poster/">
                Professional Datumstempel
              </a>
            </div>
          </NavLink>
        </div> */}
            <div className="main2-home-box1">
              <NavLink to="/buyPrintyDatumstempel">
                <img src={img15} alt="" />
                <div className="main2-home-txt1">Stempel</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/plakate-poster/">
                    Printy Datumstempel
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Fotokalender">
                <img src={img16} alt="" />
                <div className="main2-home-txt1">Fotokalender</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Fotokalender
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Broschüernkalender">
                <img src={img17} alt="" />
                <div className="main2-home-txt1">Broschüernkalender</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Broschüernkalender
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Stehkalender">
                <img src={img18} alt="" />
                <div className="main2-home-txt1">Stehkalender</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Stehkalender
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Taschenkalender">
                <img src={img19} alt="" />
                <div className="main2-home-txt1">Taschenkalender</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Taschenkalender
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Sticker">
                <img src={img20} alt="" />
                <div className="main2-home-txt1">Sticker</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Sticker
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Klebebuchstaben">
                <img src={img21} alt="" />
                <div className="main2-home-txt1">Klebebuchstaben</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Klebebuchstaben
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Klebefolien">
                <img src={img22} alt="" />
                <div className="main2-home-txt1">Klebefolien</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Klebefolien
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Laminierte Plakate">
                <img src={img23} alt="" />
                <div className="main2-home-txt1">Laminierte Plakate</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Laminierte Plakate
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Plakate">
                <img src={img25} alt="" />
                <div className="main2-home-txt1">Plakate</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Plakate
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Presspappe">
                <img src={img26} alt="" />
                <div className="main2-home-txt1">Presspappe</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Presspappe
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Schaumplatten">
                <img src={img27} alt="" />
                <div className="main2-home-txt1">Schaumplatten</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Schaumplatten
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Tischkarten">
                <img src={img28} alt="" />
                <div className="main2-home-txt1">Tischkarten</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Tischkarten
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/T Shirts">
                <img src={img29} alt="" />
                <div className="main2-home-txt1">T Shirts</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    T Shirts
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Stofftaschen">
                <img src={img30} alt="" />
                <div className="main2-home-txt1">Stofftaschen</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Stofftaschen
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Durchschreibblöcke">
                <img src={img31} alt="" />
                <div className="main2-home-txt1">Durchschreibblöcke</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Durchschreibblöcke
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Spiralblöcke">
                <img src={img32} alt="" />
                <div className="main2-home-txt1">Spiralblöcke</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Spiralblöcke
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Banner Transparente">
                <img src={img33} alt="" />
                <div className="main2-home-txt1">Banner Transparente</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Banner Transparente
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Brief">
                <img src={img34} alt="" />
                <div className="main2-home-txt1">Brief</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Brief
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Broschüren">
                <img src={img35} alt="" />
                <div className="main2-home-txt1">Broschüren</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Broschüren
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Buchdruck">
                <img src={img36} alt="" />
                <div className="main2-home-txt1">Buchdruck</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Buchdruck
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Diplomarbeit">
                <img src={img37} alt="" />
                <div className="main2-home-txt1">Diplomarbeit</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Diplomarbeit
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Eintrittskarte">
                <img src={img38} alt="" />
                <div className="main2-home-txt1">Eintrittskarte</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Eintrittskarte
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Fotoposter">
                <img src={img39} alt="" />
                <div className="main2-home-txt1">Fotoposter</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Fotoposter
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Fototapeten">
                <img src={img40} alt="" />
                <div className="main2-home-txt1">Fototapeten</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Fototapeten
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Keilrahmen">
                <img src={img41} alt="" />
                <div className="main2-home-txt1">Keilrahmen</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Keilrahmen
                  </a>
                </div>
              </NavLink>
            </div>
            {/* <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage">
                <img src={img42} alt="" />
                <div className="main2-home-txt1">Lesezeichen</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Lesezeichen
                  </a>
                </div>
              </NavLink>
            </div> */}
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Loseblattsammlung">
                <img src={img43} alt="" />
                <div className="main2-home-txt1">Loseblattsammlung</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Loseblattsammlung
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Personalisierte Brief">
                <img src={img44} alt="" />
                <div className="main2-home-txt1">Personalisierte Brief</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Personalisierte Brief
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Plandruck">
                <img src={img45} alt="" />
                <div className="main2-home-txt1">Plandruck</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Plandruck
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Rückenbandbindung">
                <img src={img46} alt="" />
                <div className="main2-home-txt1">Rückenbandbindung</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Rückenbandbindung
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Softcoverbüchern">
                <img src={img47} alt="" />
                <div className="main2-home-txt1">Softcoverbüchern</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Softcoverbüchern
                  </a>
                </div>
              </NavLink>
            </div>
            <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage/Spiralbüchern">
                <img src={img48} alt="" />
                <div className="main2-home-txt1">Spiralbüchern</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Spiralbüchern
                  </a>
                </div>
              </NavLink>
            </div>
            {/* <div className="main2-home-box1">
              <div className="ribbon">
                <div className="ribbon-wrapper">
                  <div className="ribbon-txt">kommt demnächst</div>
                </div>
              </div>
              <NavLink to="/anfrage">
                <img src={img49} alt="" />
                <div className="main2-home-txt1">Werbetechnik</div>
                <div className="main2-home-txt2">
                  <a href="https://teleprint.at/produkte/softcover-buecher/">
                    Werbetechnik
                  </a>
                </div>
              </NavLink>
            </div> */}
          </div>
        </div>
      </main>
    );
  }
}
