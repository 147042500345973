import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";

// redux
import { connect } from "react-redux";
import { updateCart } from "../../../action";
import { on } from "jetemit";

import Logo from "../../../assets/img/TelePrint-Logo.svg";

import PayPal from "../PayPal";
//api
import { order } from "../../../api/Api";
import { Drawer, Badge, Modal } from "rsuite";
class Header extends Component {
  state = {
    data: [],
    reduxCart: "",
    openDrawerState: false,
    openMenuState: false,
    openPayment: false,
    statusName: "not_paid",
    loading: false,
    orderLength: "",
    amount: 0,
    redirect: false,
  };

  componentDidMount() {
    this.getOrder();
    on("order", (order) => {
      this.getOrder();
    });
    on("logOut", () => {
      this.handleLogOut();
    });
  }

  openDrawer = async () => {
    await this.setState({ openDrawerState: true });
  };
  closeDrawer = () => {
    this.setState({ openDrawerState: false });
  };

  openMenu = async () => {
    await this.setState({ openMenuState: true });
  };
  closeMenu = () => {
    this.setState({ openMenuState: false });
  };

  getOrder = async () => {
    try {
      const personalData = JSON.parse(
        localStorage.getItem("personalData") &&
          localStorage.getItem("personalData")
      );

      if (!personalData || !personalData.phoneNumber) {
        this.setState({
          order: [],
          orderLength: 0,
        });
        return;
      }
      const newValue = {
        phoneNumber: personalData.phoneNumber,
        email: personalData.email,
        statusName: this.state.statusName,
      };
      if (!personalData || !personalData.phoneNumber) {
        return;
      }

      const { data, status } = await order.getAllOrderWithStatus(newValue);

      if (status === 200) {
        let amount = 0;
        data.data.map((product) => {
          amount += Number(product.tableId.finalPrice);
        });
        this.setState({
          order: data.data,
          orderLength: data.data.length,
          amount: amount,
        });
        return;
      }
      if (status === 204) {
        this.setState({
          order: [],
          orderLength: 0,
        });
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  handlePay = async () => {
    try {
      const personalData = JSON.parse(
        localStorage.getItem("personalData") &&
          localStorage.getItem("personalData")
      );

      const newValue = {
        phoneNumber: personalData.phoneNumber,
        email: personalData.email,
      };

      const data = await order.payment(newValue);
      localStorage.setItem("amount", data.data.data.amount);

      window.location.assign(`/payment/${data.data.data.client_secret}`);
    } catch (error) {
      console.error(error);
    }
  };

  cancelOrder = async (id) => {
    this.setState({ loading: true });
    try {
      await order.deleteOrder(id);
    } catch (error) {
      console.error(error);
    }
    await this.getOrder();
    this.setState({ loading: false });
  };
  back = () => {
    window.history.back();
  };

  handleClosePayPal = () => {
    this.setState({ openPayPal: false });
    this.getOrder();
  };

  handleOpenPayPal = () => {
    this.setState({ openPayPal: true });
  };

  handleClosePayment = () => {
    this.setState({ openPayment: false });
  };

  handleOpenPayment = () => {
    this.setState({ openPayment: true });
  };

  handleLogOut = () => {
    this.closeMenu();
    localStorage.clear();
    this.getOrder();
    this.setState({ redirect: true });
  };
  render() {
    const {
      openDrawerState,
      order,
      openMenuState,
      loading,
      orderLength,
      redirect,
    } = this.state;
    const token =
      localStorage.getItem("token") && localStorage.getItem("token");
    return (
      <header>
        {redirect ? <Redirect to="/" /> : ""}
        <Drawer
          backdrop="static"
          show={openMenuState}
          onHide={this.closeMenu}
          placement={"left"}
          size={"xs"}
        >
          <Drawer.Header>
            <Drawer.Title>Menu</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <main>
              <div className="menu w-100 flex-j-c">
                <div className="menu-dad ">
                  <ul className="menu-ul">
                    {!token ? (
                      <li className="menu-li3 ">
                        <NavLink to="/login">An­mel­den</NavLink>
                      </li>
                    ) : (
                      ""
                    )}
                    <li className="menu-li3 ">
                      <NavLink to="/">Home</NavLink>
                    </li>
                    <li className="menu-li3">
                      <NavLink to="/product">Produkte</NavLink>
                    </li>
                    {/* <li className="menu-li3">
                      <a href="https://teleprint.at/haeufige-fragen-faq/">
                        
                        Häufige Fragen (FAQ)
                      </a>
                    </li> */}
                    <li className="menu-li3">
                      <NavLink to="/anfrage/Anfrage">Anfrage</NavLink>
                    </li>
                    <li className="menu-li3">
                      <NavLink to="/aboutUs"> Über uns </NavLink>
                    </li>
                    <li className="menu-li3">
                      <NavLink to="/contactUs"> Kontakt </NavLink>
                    </li>

                    {token ? (
                      <li onClick={this.handleLogOut} className="menu-li3">
                        <span> Abmelden</span>
                      </li>
                    ) : (
                      ""
                    )}

                    <li className="menu-li2">
                      <div className="menu-li4 flex-j-c">
                        <div className="head-1-child1-right-icn-1">
                          <a href="https://www.facebook.com/search/top?q=teleprint%20digitaldruck%20kg">
                            <i className="mdi mdi-facebook"></i>
                          </a>
                        </div>
                        <div className="head-1-child1-right-icn-1">
                          <a href="https://www.instagram.com/teleprint.at/">
                            <i className="mdi mdi-instagram"></i>
                          </a>
                        </div>
                        <div className="head-1-child1-right-icn-2">
                          <a href="mailto:print@teleprint.at">
                            <i className="mdi mdi-email"></i>
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </main>
          </Drawer.Body>
        </Drawer>

        <Drawer
          backdrop="static"
          show={openDrawerState}
          onHide={this.closeDrawer}
          placement={"left"}
          size={"xs"}
        >
          <Drawer.Header>
            <Drawer.Title>Einkaufswagen</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {order && order.length
                ? order.map((x) => {
                    return (
                      // <div style={{ width: "50%" }}>
                      //   <img style={{ width: "80%" }} src={img1} />

                      // </div>
                      <div>
                        <div className="card">
                          {/* <img
                            src="/w3images/jeans3.jpg"
                            alt="Denim Jeans"
                            style={{ width: "100%" }}
                          /> */}
                          <h1>{x.productName}</h1>
                          <div className="">
                            <a
                              className="aaa"
                              rel="noreferrer"
                              target="_blank"
                              href={x.tableId && x.tableId.file1}
                            >
                              file1
                            </a>
                            <br />
                            <a
                              className="aaa"
                              rel="noreferrer"
                              target="_blank"
                              href={x.tableId && x.tableId.file2}
                            >
                              file2
                            </a>
                          </div>

                          {/* <p className="price">$19.99</p> */}

                          <p>
                            <button onClick={() => this.cancelOrder(x.id)}>
                              {loading ? "loading" : "Cancel"}
                            </button>
                          </p>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
            {order && order.length ? (
              <div className="card">
                <button
                  style={{ marginTop: "10px" }}
                  onClick={this.handleOpenPayment}
                >
                  Zahlen
                </button>
              </div>
            ) : (
              ""
            )}
          </Drawer.Body>
        </Drawer>

        <Modal
          size={"md"}
          show={this.state.openPayPal}
          onHide={this.handleClosePayPal}
        >
          <Modal.Header>
            <Modal.Title>Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PayPal
              handleClosePayPal={this.handleClosePayPal}
              handleClosePayment={this.handleClosePayment}
              amount={this.state.amount}
              closeDrawer={this.closeDrawer}
            />
          </Modal.Body>
          <Modal.Footer>
            <button onClick={this.handleClosePayPal} appearance="subtle">
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          size={"md"}
          show={this.state.openPayment}
          onHide={this.handleClosePayment}
        >
          <Modal.Header>
            <Modal.Title>Modal Title</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card">
              <button style={{ marginTop: "10px" }} onClick={this.handlePay}>
                Zahlen Stripe
              </button>

              <button
                style={{ marginTop: "10px" }}
                onClick={this.handleOpenPayPal}
              >
                Zahlen PayPal
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={this.handleClosePayment} appearance="subtle">
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <div className="head-1 w-100 flex-j-c">
          <div className="head1-dad w-90 flex-j-s">
            <div className="head-1-child1-left flex-j-s">
              <div className="head-1-child1-left-txt1">
                <a
                  target="blank"
                  rel="noopener noreferrer"
                  href="https://maps.google.com/?q=Westbahnstraße 9, 1070 Wien"
                >
                  <i className="mdi mdi-map-marker head1-icn1"></i>
                  Westbahnstraße 9, 1070 Wien
                </a>
              </div>
              <div className="head-1-child1-left-txt2">
                <a
                  target="blank"
                  rel="noopener noreferrer"
                  href="mailto:print@teleprint.at"
                >
                  <i className="mdi mdi-email-outline head1-icn1"></i>
                  print@teleprint.at
                </a>
              </div>
              <div className="head-1-child1-left-txt3">
                <a
                  target="blank"
                  rel="noopener noreferrer"
                  href="tel:+43 1 524 32 56"
                >
                  <i className="mdi mdi-phone head1-icn1"></i> +43 1 524 32 56
                </a>
              </div>
            </div>
            <div className="head-1-child1-right-icns flex">
              <div className="head-1-child1-right-icn-1">
                <a href="https://www.facebook.com/search/top?q=teleprint%20digitaldruck%20kg">
                  <i className="mdi mdi-facebook"></i>
                </a>
              </div>
              <div className="head-1-child1-right-icn-1">
                <a href="https://www.instagram.com/teleprint.at/">
                  <i className="mdi mdi-instagram"></i>
                </a>
              </div>
              <div className="head-1-child1-right-icn-2">
                <a href="mailto:print@teleprint.at">
                  <i className="mdi mdi-email"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky">
          <div className="head-2 w-100 flex-j-c">
            <div className="head2-dad w-90 flex-j-s">
              <div className="head2-child1-left flex">
                <div className="head2-child1-left-logo">
                  <NavLink to="/">
                    <img src={Logo} alt="" />
                  </NavLink>
                </div>
                {/* <div className="head2-child2-left flex-j-s">
                  <input type="search" placeholder="Produkte suchen" />
                  <i className="mdi mdi-magnify"></i>
                </div> */}
                {/* <i className="head2-icn-res mdi mdi-menu"></i> */}
              </div>
              <div className="head2-res-cntr w-100 flex-j-c">
                <div className="w-95 flex-j-s">
                  <li onClick={this.back} className="head2-icn1-res">
                    <i className="mdi mdi-arrow-left"></i>
                  </li>
                  <div className="m-t-20">
                    <NavLink to="/">
                      <img src={Logo} alt="" />
                    </NavLink>
                  </div>
                  <ul className="flex">
                    <li className="head2-res-brd1"> </li>
                    <li onClick={this.openDrawer} className="head2-icn1-res">
                      <Badge
                        content={
                          orderLength || orderLength === 0 ? orderLength : ""
                        }
                      >
                        <i className="mdi mdi-cart"></i>
                      </Badge>
                    </li>

                    <li className="head2-res-brd1"></li>
                    <li onClick={this.openMenu} className="head2-icn1-res">
                      <i className="head2-icn-res mdi mdi-menu"></i>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="head2-child1-right flex">
                {/* <div className="head2-child1-right-txt">
                  <a href="https://teleprint.at/mein-konto/">
                    teleprintdemo
                    <i className="mdi mdi-account"></i>
                  </a>
                </div> */}
                <Badge
                  content={orderLength || orderLength === 0 ? orderLength : ""}
                >
                  <div
                    onClick={this.openDrawer}
                    className="head2-child1-right-txt"
                  >
                    <span style={{ position: "absolute" }}> </span>
                    Einkaufswagen
                    <i
                      style={{ marginLeft: "5px", marginRight: "5px" }}
                      className="mdi mdi-cart"
                    ></i>
                  </div>
                </Badge>

                <div className="head2-child1-right-bord"></div>
                {/* <Dropdown title="Setting">
                  {token ? (
                    <Dropdown.Item>
                      <NavLink to={"/dashboard"}>Dashboard </NavLink>
                    </Dropdown.Item>
                  ) : (
                    ""
                  )}
                  <Dropdown.Item>
                    {token ? (
                      <li
                        onClick={this.handleLogOut}
                        className="head2-icn1-res"
                      >
                        Abmelden
                        <i
                          title="Log Out"
                          style={{ fontSize: "20px", marginLeft: "15px" }}
                          className="mdi mdi-logout"
                        ></i>
                      </li>
                    ) : (
                      <li className="head2-icn1-res">
                        <NavLink to={"/login"}>
                          An­mel­den
                          <i
                            title="An­mel­den"
                            style={{ fontSize: "20px", marginLeft: "15px" }}
                            className="mdi mdi-account"
                          ></i>
                        </NavLink>
                      </li>
                    )}
                  </Dropdown.Item>
                </Dropdown> */}
              </div>

              {/* <div className="head2-res m-t-20 t-r">
                <ul className="flex">
                  <li className="head2-res-brd1"> </li>
                  <li className="head2-icn1-res">
                    <i className="mdi mdi-account"></i>
                  </li>
                  <li className="head2-res-brd1"></li>
                  <li className="head2-icn2-res">
                    <i className="mdi mdi-briefcase-outline"></i>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <div className="head-3 w-100 flex-j-c">
            <div className="head3-dad w-90">
              <ul className="head3-child1 flex">
                <li id="head3-li1">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/product">Produkte</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/faq">Häufige Fragen (FAQ)</NavLink>
                </li> */}
                <li>
                  <NavLink to="/anfrage/Anfrage">Anfrage</NavLink>
                </li>
                <li>
                  <NavLink to="/aboutUs">Über uns </NavLink>
                </li>
                <li>
                  <NavLink to="/contactUs"> Kontakt</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
function mapStatetoProps(state) {
  return { cart: state.data.addCart };
}

const mapDispatchToProps = (dispatch) => ({
  UpdateCart: function (value) {
    return dispatch(updateCart(value));
  },
});

export default connect(mapStatetoProps, mapDispatchToProps)(Header);
