import React from "react";
import "./style.css";

export default class CustomLoader extends React.Component {
  render() {
    return (
      <div id="load">
        <div>G</div>
        <div>N</div>
        <div>I</div>
        <div>D</div>
        <div>A</div>
        <div>O</div>
        <div>L</div>
      </div>
    );
  }
}
