import React, { useState } from "react";
import ReactDOM from "react-dom";

import { order } from "../../../api/Api";
const PayPalButton =
  window &&
  window.paypal &&
  window.paypal.Buttons &&
  window.paypal.Buttons.driver("react", { React, ReactDOM });

function App({ amount, handleClosePayPal, handleClosePayment, closeDrawer }) {
  const [error, setError] = useState(null);

  const paypalStart = async () => {
    try {
      const personalData = JSON.parse(
        localStorage.getItem("personalData") &&
          localStorage.getItem("personalData")
      );
      const newValue = {
        phoneNumber: personalData.phoneNumber,
        email: personalData.email,
      };
      if (!personalData || !personalData.phoneNumber) {
        return;
      }
      await order.paypalStart(newValue);
    } catch (error) {
      console.error(error);
    }
  };

  const paypalPayment = async (value) => {
    try {
      const capture = value.purchase_units[0].payments.captures[0].id;
      const personalData = JSON.parse(
        localStorage.getItem("personalData") &&
          localStorage.getItem("personalData")
      );
      const newValue = {
        phoneNumber: personalData.phoneNumber,
        email: personalData.email,
        id: capture,
      };

      const { data, status } = await order.paypalPayment(newValue);
      if (status === 200) {
        handleClosePayPal();
        handleClosePayment();
        closeDrawer();
      }
      console.log("dataaa", data);
    } catch (error) {
      console.error(error);
    }
  };

  const createOrder = async (data, actions) => {
    await paypalStart();
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: "EUR",
            value: amount,
          },
        },
      ],
    });
  };
  const onApprove = async (data, actions) => {
    const order = await actions.order.capture();

    await paypalPayment(order);
    console.log(order);
  };

  const onError = async (err) => {
    setError(err);
    console.error(err);
  };

  return (
    <div>
      {error && <div>Uh oh, an error occurred! {error.message}</div>}
      <div style={{ textAlign: "center", padding: "20px", color: "gray" }}>
        {amount}EURO
      </div>
      {/* <div ref={paypalRef} /> */}
      <PayPalButton
        createOrder={(data, actions) => createOrder(data, actions)}
        onApprove={(data, actions) => onApprove(data, actions)}
        onError={(error) => onError(error)}
      />
    </div>
  );
}

export default App;
